<template>
    <div class="section-bgc intro"  >
        <div class="intro-item intro-item-type-1" :style="{backgroundImage: 'url(' + require('../assets/img/aces_bmg.jpg') + ')'}">
            <div class="container" style="float: right;">
                <div class="row">
                    <div class="col">
			    <!--<div style="--margin-right: 4rm;">-->
	                 <div >
                        <!--<div  style="--margin-right: 4rem;">-->
                            <div  style="float:right;">
                                <div class="section-subheading">MeshTek</div>
                                <p style="text-align: center;color:#FFFFFF;font-size:40px;">支持业界ACES色彩空间标准
                                </p>
                                <p class="section-desc" style="color:#FFFFFF">支持ACES和ACES GamutComp</p>
                                <p class="section-desc" style="color:#FFFFFF">内置ACES色彩标准模块，无需用户设置</p>
                            
                            </div>
                        </div>
                     </div>
                 </div>
              </div>
           </div>
        </div>

</template>


<script>
export default {
  name: 'IntroShow',
  props: {
    Zmsg: String
  }
}
</script>

<style lang="scss" scoped>

@import "../assets/css/bootstrap-grid.css";
@import "../assets/css/style.css";

h1 {
  font-size: 12px; 
  font-weight: normal;
  color: #000000;

}

br{
color: red;
}
</style>

